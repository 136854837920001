@charset "UTF-8";

// 1. Configuration and helpers
// @import
//   'abstracts/variables',
//   'abstracts/functions',
//   'abstracts/mixins';

// 2. Vendors
// @import
//   'vendors/normalize';

// 3. Base stuff
// @import
//   'base/base',
//   'base/fonts',
//   'base/typography',
//   'base/helpers';

// 4. Layout-related sections
@import 'layout/header',
'layout/footer';

// 5. Components
@import 'components/button';

// 6. Page-specific styles
@import 'pages/login',
'pages/_resetPassword.scss',
'pages/register',
'pages/verifyCode',
'pages/_pricing.scss',
'pages/error';

// 7. Themes
@import 'themes/default';

//main section css 
.section-style {
  max-width: 32.22vw  !important;
}

.container-style {
  max-width: 33.33vw !important;
}

// end main section css 

// all heading css 
.MuiTypography-root.main_heading {
  font-size: 2.222vw;
  line-height: 3.333vw;
  font-weight: 700;
}

.MuiTypography-root.heading_24 {
  font-size: 1.66vw;
  line-height: 2.5vw;
  font-weight: 700;
}

.MuiTypography-root.heading_16 {
  font-size: 1.11vw;
  line-height: 1.66vw;
  font-weight: 400;
}

.MuiTypography-root.heading_14 {
  font-size: 0.9722vw;
  line-height: 1.527vw;
}

.MuiTypography-root.heading_12 {
  font-size: 0.833vw;
  line-height: 1.25vw;
}

// all heading css end 

// placeholder css 

::-webkit-input-placeholder {
  /* Edge */
  font-size: 1.11vw;
  line-height: 1.66vw;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-size: 1.11vw;
  line-height: 1.66vw;
}

::placeholder {
  font-size: 1.11vw;
  line-height: 1.66vw;
}

//placeholder css end 


//social login code 
.social-box {
  // display: inline-block;
  border: 1px solid rgba(145, 158, 171, 0.32);
  border-radius: 8px;
   padding: 0.85vw 0vw;
    margin-right: 0.694vw;
    align-items: center;
    display: flex;
    justify-content: center;
}

.social_icon {
  width: 1.66vw !important;
  height: 1.66vw !important;
  object-fit: contain !important;
}

.social-section {
  margin-top: 2.778vw;
  margin-bottom: 2vw;
}

.logo-img {
  width: 2.875vw !important;
  height: 2.875vw !important;
}

.only_social_section {
  fieldset {
    border: 1px solid transparent;
    border-top-color: rgba(145, 158, 171, 0.24);
    box-sizing: border-box;
    grid-area: 1 / 1;
    width: inherit;
    margin-bottom: 2vw;
  }

  fieldset:nth-of-type(2) {
    transform: rotate(90deg);
  }

  legend {
    margin: auto;
    padding: 0 0.64vw;
    text-align: center;
    font-size: 0.9722vw;
    line-height: 1.527vw;
    font-weight: 600;
    color: #637381;

  }

}

.card_height {
  height: 20vw;
}

//social login code end 
@media only screen and (max-width:899px) {
  .section_style {
    max-width: 95% !important;
    margin: auto;
  }

  .container-style {
    max-width: 98% !important;
    margin: auto;
  }

  // all heading css 
  .MuiTypography-root.main_heading {
    font-size: 24px;
    line-height: 36px;
  }

  .MuiTypography-root.heading_24 {
    font-size: 24px;
    line-height: 32px;
  }

  .MuiTypography-root.heading_16 {
    font-size: 16px;
    line-height: 24px;
  }

  .MuiTypography-root.heading_14 {
    font-size: 14px;
    line-height: 22px;
  }

  .MuiTypography-root.heading_12 {
    font-size: 12px;
    line-height: 18px;
  }

  // placeholder css 

  ::-webkit-input-placeholder {
    /* Edge */
    font-size: 16px;
    line-height: 24px;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-size: 16px;
    line-height: 24px;
  }

  ::placeholder {
    font-size: 16px;
    line-height: 24px;
  }

  //placeholder css end 

  //social login code 
  .social_icon {
    width: 24px !important;
    height: 24px !important;
    object-fit: scale-down !important;
  }

  .social-box {
    margin-right: 10px;
    padding: 8px 0;
  }

  .social-section {
    margin-top: 40px;
    margin-bottom: 34px
  }

  .only_social_section {
    fieldset {
      margin-bottom: 34px
    }

    legend {
      font-size: 14px;
      line-height: 22px;
    }
  }

  //social login code end 

  .logo-img {
    width: 40px !important;
    height: 40px !important;
  }

  .card_height {
    height: 250px;
  }
}
.dashboard{
  .slick-slider .MuiBox-root {
    right: 24px;
    bottom: 24px;
    align-items: end;
    justify-content: end;
    top: 0;
    left: 0;
  }
}
@media only screen and (min-width:1600px) {
  // placeholder css 

  ::-webkit-input-placeholder {
    /* Edge */
    font-size: 1.66vw;
    line-height: 2vw;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-size: 1.66vw;
    line-height: 2vw;
  }

  input::placeholder::placeholder {
    font-size: 1.66vw;
    line-height: 2vw;
  }
}

.timeBTn{
  color:#212B36 !important;
  background-color: transparent !important;
  padding-left: 0 !important;
  font-weight: 400 !important;
  ;

}
// .rbc-event-label {
//   display: none;
// }
// .rbc-agenda-time-cell{
//   display: none !important;
// }
// th.rbc-header.time {
//     width: 0px;
//     display: none !important;
// }

.fc .fc-list-sticky .fc-list-day > * {
    position: sticky;
    top: 0;
    background: #fff;
    background: var(--fc-page-bg-color, #3788d8) !important;
}

.fc .fc-list-event:hover td {
    background-color: #fff !important ;
    background-color: transparent !important;
}

.Filter {
    float: right !important;
cursor: pointer;
}

.main_container{
 clear: both !important;
}

.dropdown_container{
  max-width: 100% !important;
 .MuiFormControl-root.MuiTextField-root{
  display: flex;
 }
}
.stack-center{
  align-items: center !important;
}